import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Api from '../utils/api';

export default function useApp(): [onload: boolean, error: boolean, jwt: string, id: string, pcoPasajero: string, platform: string] {
  const [jwt, setJwt] = useState('');
  const [pcoPasajero, setPcoPasajero] = useState('');
  const [id, setId] = useState('');
  const [platform, setPlatform] = useState('android');
  const [onload, setOnload] = useState(true);
  const [error, setError] = useState(false);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const pnu_documento = searchParams.get('pnu_documento') ?? '';
    const pd_token = searchParams.get('pd_token') ?? '';
    setPlatform(searchParams.get('platform') ?? 'android');

    if (!pnu_documento || !pd_token) return;
    setId(pnu_documento);
    Api('tokenApp')
      .post({ pnu_documento, pd_token })
      .then(res => {
        if (!res.jwt) throw new Error("hubo un problema al obtener el token");
        setPcoPasajero(res.idPax);
        setJwt(res.jwt);
      })
      .catch(() => setError(true))
      .finally(() => setOnload(false))

  }, [searchParams]);

  return [onload, error, jwt, id, pcoPasajero, platform];
}