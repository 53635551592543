import { useEffect, useState } from "react";
import useApp from "../../hooks/useApp.hook"
import Api from "../../utils/api";
import IServicioIndividual from "../../interfaces/IServicioIndividual";
import Modal from "../../components/Modal";
import Button from "../../components/Button";

interface IPropsRow {
  servicio: IServicioIndividual,
  i: number,
  togglePagoOnline: Function
}

interface IPropsModalMercadoPago {
  show: boolean,
  onClose: Function,
  cuotas: Array<{ NU_TALON: string; IMP_PAGAR: number }>,
  jwt: string,
  pcoPasajero: string,
  platform: string,
}

function ModalMercadoPago({ show, onClose, cuotas, jwt, pcoPasajero, platform }: IPropsModalMercadoPago) {
  const [onLoadMP, setOnLoadMP] = useState(false);

  const handleClickMP = () => {
    setOnLoadMP(true);

    let ptalones = cuotas.reduce((acc: string, e) => acc + `${e.NU_TALON},`, '');
    const pimporte = cuotas.reduce((acc: number, e) => acc + e.IMP_PAGAR, 0);
    ptalones = ptalones.slice(0, -1);
    Api("consumir", jwt)
      .post({
        param: {
          pco_pasajero: pcoPasajero,
          pimporte,
          ptalones,
        },
        servicio: "pagarMercadoPagoInicio"
      }).then(res => {
        if (!res.PPAGOTOKEN) {
          return;
        }
        redirectMP(res.PPAGOTOKEN, platform);
      })
      .finally(() => setOnLoadMP(false));
  };

  const redirectMP = (pagoToken: string, platform: string) => {
    Api("pagoMercadoPago", jwt)
      .post({
        pagoToken,
        url_mp_success: platform === "android" ? "com.maxdreamapp://url_mp_success" : 'maxdream://url_mp_success',
        url_mp_failure: platform === "android" ? "com.maxdreamapp://url_mp_failure" : 'maxdream://url_mp_failure',
        url_mp_pending: platform === "android" ? "com.maxdreamapp://url_mp_pending" : 'maxdream://url_mp_pending',
        url_decidir_success: platform === "android" ? "com.maxdreamapp://url_decidir_success" : 'maxdream://_decidir_success',
        url_decidir_failure: platform === "android" ? "com.maxdreamapp://url_decidir_failure" : 'maxdream://url_decidir_failure',
        url_decidir_pending: platform === "android" ? "com.maxdreamapp://url_decidir_pending" : 'maxdream://url_decidir_pending',
      }).then(res => {
        onClose();
        if (!res.url) {
          alert(`PD_ERROR: ${res.PD_ERROR}`);
          return;
        }
        (window as any).ReactNativeWebView.postMessage(res.url);
      }).finally(() => {
        setOnLoadMP(false);
      });
  };

  return (
    <Modal title="Pago online" onClose={() => onClose()} show={show}>
      <h5 className="text-center my-3">Selecciona el método de pago</h5>
      <div>
        <div className="my-2">
          <Button block onLoad={onLoadMP} title="Mercado Pago" onPress={() => handleClickMP()} />
        </div>
      </div>
    </Modal>
  )
}


function Row({ servicio, i, togglePagoOnline }: IPropsRow) {
  return (
    <div className="grid-row">
      <span title="Servicio individual" className="th-title">{i}</span>
      <span title="Fecha vencimiento">{servicio.F_VENCIMIENTO}</span>
      <span title="Importe">{servicio.MONEDA}{servicio.D_IMPORTE}</span>
      <span title="Pago online">
        <input type="checkbox" onChange={e => togglePagoOnline(servicio, e.target.checked)} />
      </span>
      <span title="Tipo">{servicio.D_TIPO}</span>
      <span title="Lista de servicios">{servicio.D_LISTA_SERVICIOS}</span>
      <span title="Imprimir"></span>
    </div>);
}

export default function ComprarServicio() {
  const [messageApi, setMessageApi] = useState("");
  const [, setIsLoad] = useState(true);
  const [serviciosIndividuales, setServiciosIndividuales] = useState<IServicioIndividual[]>([]);
  const [onload, error, jwt, id, pcoPasajero, platform] = useApp();
  const [msgError, setMgError] = useState<string>("");

  const [pagoOnline, setPagoOnline] = useState<Array<IServicioIndividual>>([]);
  const [totalPagoOnline, setTotalPagoOnline] = useState<number>(0);

  const [showModalPago, setShowModalPago] = useState(false);

  useEffect(() => {
    const e = document.querySelector('meta[name="viewport"]');
    e?.setAttribute('content', "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0");
  }, []);

  useEffect(() => {
    if (!jwt) return;

    Api("consumir", jwt)
      .post({
        param: {
          pnu_documento: id
        },
        servicio: 'paxSerIndividual'
      }).then(res => {
        setIsLoad(false);
        setMessageApi(res.PD_MENSAJE);
        setServiciosIndividuales(res.PREF_SERIND.map((e: any) => ({
          ...e,
          BOT_IMPRIMIR: e.BOT_IMPRIMIR === 'S',
          IMP_PAGAR: Number(e.IMP_PAGAR),
        })))
      })
  }, [jwt, id]);

  useEffect(() => {
    setMgError("");
    setTotalPagoOnline(pagoOnline.reduce((acc: number, e: IServicioIndividual) => acc + e.IMP_PAGAR, 0));
  }, [pagoOnline]);

  const togglePagoOnline = (servicio: IServicioIndividual, state: boolean) => {
    if (state) {
      setPagoOnline([...pagoOnline, servicio]);
      return;
    }
    setPagoOnline(pagoOnline.filter((e: IServicioIndividual) => e.NU_TALON !== servicio.NU_TALON))
  }

  const handleClickPagoOnline = () => {
    if (!pagoOnline.length) {
      setMgError("Por favor, selecciona alguna cuota");
      return;
    }
    setShowModalPago(true);
  }

  if (onload) return <h1>Cargando...</h1>
  if (error) {
    if (platform === "android") (window as any).ReactNativeWebView.postMessage('com.maxdreamapp://url_mp_failure');
    return <h1>Error</h1>;
  }

  return (
    <div className="container-data">
      <div className="message-api" dangerouslySetInnerHTML={{ __html: messageApi }}></div>
      <div className="servicios-previaje">
        <h4>Servicios Pre-Viaje:</h4>
        <div className="d-grid grid-container">
          <div className="th-platform">
            <span>Fecha vencimiento</span>
            <span>Importe</span>
            <span>Pago online</span>
            <span>Tipo</span>
            <span>Lista de servicios</span>
            <span></span>
          </div>
          <div className="td-platform">
            {
              serviciosIndividuales.map((servicio, i) => <Row key={servicio.NU_TALON} servicio={servicio} i={i + 1} togglePagoOnline={(a: IServicioIndividual, b: boolean) => togglePagoOnline(a, b)} />)
            }
          </div>
        </div>
        <div className="my-3 alert alert-warning text-center h5">
          Importe neto a pagar: <span>${new Intl.NumberFormat('es-AR', { minimumFractionDigits: 2 }).format(totalPagoOnline)}</span>
        </div>
        <span className="btn btn-platform w-100" onClick={() => handleClickPagoOnline()}>Pago online</span>
        <h5 className="mt-2 text-danger text-center">{msgError}</h5>
        <ModalMercadoPago
          platform={platform}
          jwt={jwt}
          show={showModalPago}
          onClose={() => setShowModalPago(false)}
          cuotas={pagoOnline.map(e => ({ NU_TALON: e.NU_TALON, IMP_PAGAR: +e.IMP_PAGAR }))}
          pcoPasajero={pcoPasajero}
        />
      </div>
    </div>
  )
}